import React from "react"
import Layout from "../components/layouts/Default"
import Container from "../components/layouts/container/Container"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Newsletter gesendet" desc="">
      <Container className="my-8 mmd:my-16">
        <div className="text-lg max-w-prose mx-auto">
          <div className="block text-base text-center text-gray-700 font-semibold tracking-wide uppercase">
            Newsletter
          </div>
          <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Bestätige Deine Anmeldung in Deinem Postfach
          </h1>
          <div className="mt-8 text-xl text-gray-500 leading-8 prose text-center">
            <p>
              Vielen Dank für Deine Newsletter-Anmeldung. Damit wir überprüfen
              können, ob Du Deine echte E-Mail-Adresse angegeben hast, bestätige
              bitte die Anmeldung in Deinem Postfach.
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
